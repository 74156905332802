import React, { useState, useEffect, useCallback } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import BlackButton from "../../BlackButton1";
import TitleAndDirectory from "../HOME/TitleAndDirectory";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../../App.css";

type CacheType = {
  [key: string]: string[];
};

const DevPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [devTitle, setDevTitle] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [mylist, setMylist] = useState<string[]>([]);
  const [cache, setCache] = useState<CacheType>({});
  const [fetchError, setFetchError] = useState<string | null>(null);
  const CLIENT_ID = "31d63b14d7704a7fbcfce59e811ff454";
  const CLIENT_SECRET = "8cdeaa6af08e446aa8c2f9f629831abc";

  const handleCopyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    const authParameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `grant_type=client_credentials&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
    };
    fetch("https://accounts.spotify.com/api/token", authParameters)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token))
      .catch((error) => console.error("Error fetching access token:", error));
  }, []);

  const fetchAlbumImageUrls = useCallback(async () => {
    const cacheKey = `AlbumOrSong-${devTitle}`;
    if (cache[cacheKey]) {
      setMylist(cache[cacheKey]);
      return;
    }

    const searchParameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/search?q=artist:${devTitle}&type=album`,
        searchParameters
      );
      if (!response.ok) {
        throw new Error("Failed to fetch albums");
      }
      const data = await response.json();
      const newMylist = data.albums.items.map(
        (album: any) => album.images[0].url
      );
      setMylist(newMylist);
      setCache((prevCache) => ({ ...prevCache, [cacheKey]: newMylist }));
      setFetchError(null);
    } catch (error) {
      console.error("Error fetching albums:", error);
      setFetchError("Failed to fetch album images. Please try again.");
    }
  }, [accessToken, devTitle, cache]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevTitle(event.target.value);
  };

  const handleSearchButtonPressed = () => {
    if (devTitle !== "") {
      fetchAlbumImageUrls();
    }
  };

  return (
    <>
      <TitleAndDirectory />
      {isMobile ? <br /> : null}
      <div className={isMobile ? "faq2" : "faq"} style={{ marginBottom: 40 }}>
        <h2 style={{ textAlign: "center" }}>
          ALBUM/SONG ART (Courtesy of the Spotify API)
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={devTitle}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: 350, m: 1 }}
            size="small"
          />

          <div style={{ margin: 1 }}>
            <BlackButton
              buttonText={"SEARCH"}
              marg={1}
              high={40}
              onClick={handleSearchButtonPressed}
            />
          </div>
        </div>
      </div>
      {mylist.length > 0 ? (
        <div
          className={isMobile ? "faq2" : "faq"}
          style={{ textAlign: "center" }}
        >
          <div className="dev-album-or-song-art-cont">
            {mylist.map((url, index) => (
              <div className="dev-album-or-song-art-item">
                <img
                  key={index}
                  src={url}
                  alt={`Album ${index}`}
                  width={150}
                  height={150}
                />
                <IconButton
                  onClick={() => {
                    handleCopyToClipboard(url);
                  }}
                  sx={{ color: "lightgray" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {fetchError && (
        <div style={{ color: "red", textAlign: "center" }}>{fetchError}</div>
      )}
    </>
  );
};

export default DevPage;
